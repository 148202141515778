import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/pages/GeneralViews/NotFoundPage')
const Forbidden = () => import(/* webpackChunkName: "NotFound" */ '@/pages/GeneralViews/ForbiddenPage')
const Login = () => import(/* webpackChunkName: "Login" */ '@/pages/Auth/Login.vue')
const Logout = () => import(/* webpackChunkName: "Logout" */ '@/pages/Auth/Logout.vue')
const GeneralScreenDashboard = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/GeneralScreenDashboard.vue')
const EmptyContent = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/EmptyContent.vue')
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Dashboard/index.vue')

const PackageCategoryList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/PackageCategory/List.vue')
const PackageCategoryForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/PackageCategory/Form.vue')
const PackageCategoryDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/PackageCategory/Detail.vue')

const PackageList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Package/List.vue')
const PackageForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Package/Form.vue')
const PackageDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Package/Detail.vue')

const AgentList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Agent/List.vue')
const AgentForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Agent/Form.vue')
const AgentDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Agent/Detail.vue')

const SuperAdminList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/SuperAdmin/List.vue')
const SuperAdminForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/SuperAdmin/Form.vue')
const SuperAdminDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/SuperAdmin/Detail.vue')

const OrderList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Order/List.vue')
const OrderDeletedList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Order/DeletedList.vue')
const OrderForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Order/Form.vue')
const OrderDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Order/Detail.vue')

const WaMessageConfigForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Setting/WaMessageConfig.vue')

const SettingForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Setting/Form.vue')

const routes = [
    {
      path: '/logout',
      component: Logout,
      meta: { requireAuth: true }
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      component: GeneralScreenDashboard,
      children: [
        {
          path: 'login',
          name: 'Login Account',
          component: Login
        }
      ],
      meta: { requireGuest: true }
    },
    {
      path: '/',
      component: GeneralScreenDashboard,
      children: [
        {
          // path: 'index',
          path: '/',
          name: 'Dashboard',
          redirect: 'orders',
          component: Dashboard
        }, {
          path: '/setting',
          name: 'Empty Content Setting Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Pengaturan Akun',
              component: SettingForm,
              meta: { requireAuth: true }
            }, {
              path: 'wa-message',
              name: 'Pengaturan Pesan WA',
              component: WaMessageConfigForm,
              meta: { requireAuth: true }
            }
          ]
        }, {
            path: 'package-categories',
            name: 'Empty Content Kategori Produk Container',
            component: EmptyContent,
            children: [
              {
                path: 'index',
                alias: '/',
                name: 'Listing Kategori Produk',
                component: PackageCategoryList
              },
              {
                path: 'create',
                name: 'Tambah Data Kategori Produk',
                component: PackageCategoryForm,
                // meta: { needPermission: 'pengadaan.create' }
              },
              {
                path: ':id',
                name: 'Detail Data Kategori Produk',
                component: PackageCategoryDetail
              },
              {
                path: ':id/edit',
                name: 'Ubah Data Kategori Produk',
                component: PackageCategoryForm
              }
            ]
          }, {
            path: 'packages',
            name: 'Empty Content Produk Container',
            component: EmptyContent,
            children: [
              {
                path: 'index',
                alias: '/',
                name: 'Listing Produk',
                component: PackageList
              },
              {
                path: 'create',
                name: 'Tambah Data Produk',
                component: PackageForm,
                // meta: { needPermission: 'pengadaan.create' }
              },
              {
                path: ':id',
                name: 'Detail Data Produk',
                component: PackageDetail
              },
              {
                path: ':id/edit',
                name: 'Ubah Data Produk',
                component: PackageForm
              }
            ]
          }, {
            path: 'agents',
            name: 'Empty Content CS Container',
            component: EmptyContent,
            children: [
              {
                path: 'index',
                alias: '/',
                name: 'Listing CS',
                component: AgentList
              },
              {
                path: 'create',
                name: 'Tambah Data CS',
                component: AgentForm,
                // meta: { needPermission: 'pengadaan.create' }
              },
              {
                path: ':id',
                name: 'Detail Data CS',
                component: AgentDetail
              },
              {
                path: ':id/edit',
                name: 'Ubah Data CS',
                component: AgentForm
              }
            ]
          }, {
            path: 'super-admins',
            name: 'Empty Content Admin Container',
            component: EmptyContent,
            children: [
              {
                path: 'index',
                alias: '/',
                name: 'Listing Admin',
                component: SuperAdminList
              },
              {
                path: 'create',
                name: 'Tambah Data Admin',
                component: SuperAdminForm,
                // meta: { needPermission: 'pengadaan.create' }
              },
              {
                path: ':id',
                name: 'Detail Data Admin',
                component: SuperAdminDetail
              },
              {
                path: ':id/edit',
                name: 'Ubah Data Admin',
                component: SuperAdminForm
              }
            ]
          }, {
            path: 'orders',
            name: 'Empty Content Order Container',
            component: EmptyContent,
            children: [
              {
                path: 'index',
                alias: '/',
                name: 'Listing Order',
                component: OrderList
              },
              {
                path: 'deleted-list',
                alias: '/',
                name: 'Listing Order Terhapus',
                component: OrderDeletedList
              },
              {
                path: 'create',
                name: 'Tambah Data Order',
                component: OrderForm,
                // meta: { needPermission: 'pengadaan.create' }
              },
              {
                path: ':id',
                name: 'Detail Data Order',
                component: OrderDetail
              },
              // {
              //   path: ':id/edit',
              //   name: 'Ubah Data Order',
              //   component: OrderForm
              // }
            ]
          }
      ],
      meta: { requireAuth: true }
    }, {
      path: '/403',
      component: Forbidden
    }, {
      path: '*', component: NotFound
    }
  ]
  
  Vue.use(VueRouter)
  const router = new VueRouter({
    routes, // short for router: router
    linkActiveClass: 'active'
  })
  
  if (store.getters['account/isLoggedIn']) {
    store.dispatch('account/saveTokens', {tokens: store.getters['account/tokens'], accountData: null, isLocalLogin: true})
  }
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters['account/isLoggedIn']) {
        next({
          path: '/auth/login',
          query: { redirect: to.fullPath }
        })
      } else if (to.matched.some(record => record.meta.needPermission)) {
        const needPermission = typeof to.meta.needPermission === 'function' ? to.meta.needPermission(to) : to.meta.needPermission
        if (typeof to.meta.needPermission !== 'undefined' && store.getters['account/needPermission'](needPermission)) {
          next()
        } else {
          next({
            path: '/403'
          })
        }
      } else {
        next()
      }
    } else if (to.matched.some(record => record.meta.requireGuest)) {
      if (store.getters['account/isLoggedIn']) {
        next({
          path: '/',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    }  else {
      next()
    }
    window.scrollTo(0, 0)
  })
  
  export default router
